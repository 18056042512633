<template>
  <b-dropdown
    :mobile-modal="false"
    position="is-bottom-left"
    class="has-text-left"
    :disabled="!availableActions.length"
  >
    <a slot="trigger" class="button">Manage</a>
    <b-dropdown-item
      v-for="(action, index) in availableActions"
      :key="index"
      :class="{ [action.class]: true }"
      @click="action.action"
      >{{ action.label }}</b-dropdown-item
    >
  </b-dropdown>
</template>

<script>
import { enums as statuses } from "@/data/contract";
import { type } from "@/data/product";

export default {
  name: "ContractAction",
  props: {
    contract: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      processing: false
    };
  },
  computed: {
    isAgent() {
      return this.$store.getters["user/isAgent"]();
    },
    productTerm() {
      if (!this.hasProductTerm) return null;
      return this.$store.getters["products/term"]({
        productId: this.contract.product.ref.id,
        termId: this.contract.product.term.ref.id
      });
    },
    actions() {
      const alwaysOnActions = [
        {
          if: () =>
            /**CANCELED, ACTIVE */
            [statuses.CANCELLED, statuses.ACTIVE].includes(
              this.contract.status
            ) &&
            this.productTerm &&
            this.productTerm.upgrades,
          label: "Upgrade plan",
          action: this.upgradePlan
        },
        {
          /**If not LAPSED can be cancelled/terminated by an agent */
          if: () => this.isAgent && statuses.LAPSED !== this.contract.status,
          label:
            this.contract.status > statuses.CANCELLED ? "Cancel" : "Terminate",
          action: this.cancelByAgent
        }
      ];

      return {
        "-1": [
          {
            if: () => this.contract.provision.type === type.SUBSCRIPTION,
            label: "Resubscribe",
            class: "has-text-dark",
            action: this.upgrade
          },
          ...alwaysOnActions
        ],
        "0": [
          {
            if: () => true,
            label: "Reactivate",
            class: "has-text-dark",
            action: this.reactivate
          },
          ...alwaysOnActions
        ],
        "1": [
          {
            if: () => !this.isAgent,
            label: "Cancel",
            action: this.cancel
          },
          ...alwaysOnActions
        ],
        "2": [
          {
            if: () => true,
            label: "Make payment",
            class: "has-text-danger",
            action: this.renew
          },
          {
            if: () => !this.isAgent,
            label: "Cancel",
            class: "has-text-grey",
            action: this.cancel
          },
          ...alwaysOnActions
        ]
      };
    },
    availableActions() {
      return this.$_.filter(this.actions[this.status], action => action.if());
    },
    contractId() {
      return this.contract._id;
    },
    status() {
      return this.contract.status;
    },
    hasProductTerm() {
      return (
        this.$_.get(this.contract, "product.ref.id") &&
        this.$_.get(this.contract, "product.term.ref.id")
      );
    }
  },
  created() {
    this.observeProductTerm();
  },
  beforeDestroy() {
    this.unobserveProductTerm();
  },
  methods: {
    observeProductTerm() {
      if (!this.hasProductTerm) return;

      this.$store.dispatch("products/observeTerm", {
        productId: this.contract.product.ref.id,
        termId: this.contract.product.term.ref.id
      });
    },
    unobserveProductTerm() {
      if (!this.hasProductTerm) return;

      this.$store.dispatch("products/unobserveTerm", {
        productId: this.contract.product.ref.id,
        termId: this.contract.product.term.ref.id
      });
    },
    cancel() {
      this.$modal.open({
        component: () => import("./_cancellationFeedbackModal"),
        parent: this.$parent,
        width: 480,
        hasModalCard: true,
        canCancel: ["escape", "outside"],
        props: {
          contract: this.contract
        }
      });
    },
    upgradePlan() {
      this.$modal.open({
        component: () => import("@shared/contracts/_upgradePlanModal"),
        parent: this,
        width: 720,
        hasModalCard: true,
        canCancel: ["escape", "outside"],
        props: {
          contract: this.contract
        },
        events: {
          updated: () => {
            this.$emit("updated", this.contract);
          }
        }
      });
    },
    upgrade() {
      this.$modal.open({
        component: () => import("@shared/contracts/_upgradeModal"),
        parent: this,
        width: 960,
        hasModalCard: true,
        canCancel: ["escape", "outside"],
        props: {
          fqdn: this.contract.site.fqdn,
          siteId: this.$_.get(this.contract, "site.ref.id"),
          userId: this.contract.authorId
        }
      });
    },
    reactivate() {
      this.processing = true;
      this.$store
        .dispatch("billing/reactivateContract", {
          contractId: this.contractId
        })
        .then(result => {
          this.$toast.open({
            message: `${result}`
          });
        })
        .catch(error => {
          this.$toast.open({
            message: `${error}`,
            type: "is-danger"
          });
        })
        .finally(() => {
          this.processing = false;
        });
    },
    renew() {
      this.$modal.open({
        component: () => import("@shared/cart/_renewModal"),
        parent: this.$parent,
        width: 640,
        hasModalCard: true,
        canCancel: [],
        props: {
          userId: this.contract.authorId,
          contractId: this.contractId
        }
      });
    },
    cancelByAgent() {
      this.$modal.open({
        component: () => import("@shared/contracts/_cancelModal"),
        parent: this,
        width: 720,
        hasModalCard: true,
        canCancel: ["escape", "outside"],
        props: {
          contractId: this.contractId
        },
        events: {
          updated: () => {
            this.$emit("updated", this.contract);
          }
        }
      });
    }
  }
};
</script>
